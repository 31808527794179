import getData from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/constants.mjs";
import MapStatsModel from "@/data-models/cs2-individual-map-stats.mjs";
import CsgoStatsMaps from "@/data-models/csgo-stats-maps.mjs";

const constantOptions = {
  networkBackOffTime: 4 * 60 * 60 * 1000, // 4 hrs
};

function fetchMapsStats() {
  const url = `${appURLs.UTILS_STATIC}/csgo/stats-test/data/maps_stats:queue=ALL&rank=ALL`;
  return getData(url, CsgoStatsMaps, ["cs2", "stats", "maps"], constantOptions);
}

function fetchMapStats([mapId]) {
  const url = `${appURLs.UTILS_STATIC}/csgo/stats-test/data/indiv_maps_stats:queue=ALL&rank=ALL&map_code=${mapId}`;
  return Promise.all([
    getData(
      url,
      MapStatsModel,
      ["cs2", "stats", "pastMapStats", mapId],
      constantOptions,
    ),
    fetchMapsStats(),
  ]);
}

export default fetchMapStats;
