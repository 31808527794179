import createModel, { Silent } from "@/__main__/data-model.mjs";

const baseModel = {
  num_matches: Silent(Number),
  num_rounds: Silent(Number),
  pick_rate: Silent(Number),
  t_win_rate: Silent(Number),
  ct_win_rate: Silent(Number),
};

const model = {
  ...baseModel,
  past_days_stats: [
    {
      dt: Date,
      ...baseModel,
    },
  ],
};

const MapStatsModel = createModel(model);
export default MapStatsModel;
